import React from 'react';
import i18n from '../i18n';
import { withRouter } from "react-router";
import Header from "../Components/Header";

class ThanksPage extends React.Component {

    constructor() {
        super();
        this.state = {
            surveyStructure: [],
            feedbackId: 0,
            userGanalyticsId:null,
            responses: {}
        }
    }

    componentDidMount = async () => {
        document.title = i18n.t('page_title');
    }

    goHome = (e) => {
        // Terminado
        e.preventDefault()
        document.location.href = "/"; // hack to WA none-react theme
    }

    goTryMe = (e) => {
        // Terminado
        e.preventDefault()
        let email = localStorage.getItem('email');
        this.props.history.push({ pathname: '/tryme', state: { email } });

    }



    render() {
        return (
            <div style={{ backgroundColor: '#272727' }}>
                <Header />
                <section id="thanksPage" className="page-section bg-dark">
                    <div className="container">

                        <div className="row rowTitle" style={{ marginTop: 40 }}>
                            <h1 style={{ color: '#ddd' }}>{i18n.t('thankspage_thanks')}</h1>
                            <p style={{ color: '#ddd' }}>{i18n.t('thankspage_subheading')}</p>
                        </div>



                        <button type="button" className="btn btn-primary btn-block btn-lg" style={{ marginTop: 20 }} onClick={this.goHome}>{i18n.t('thankspage_gohome')}</button>
                        <button type="button" className="btn btn-primary btn-block btn-lg" style={{ marginTop: 20 }} onClick={this.goTryMe}>{i18n.t('thankspage_gotryme')}</button>



                    </div>
                </section>
            </div>
        )

    }

}

export default withRouter(ThanksPage);