import React from 'react';
import config from '../config.json';
import i18n from '../i18n';
import * as faceapi from 'face-api.js'
import * as Sentry from '@sentry/browser';
import { withRouter } from "react-router";
import ReactGA from 'react-ga';
import Header from '../Components/Header';
import Loading from '../Components/Loading';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

var liveEmotions = {};
let predictedAges = [];
var meStream;

// NN setup
let nnUsed = 'tinyFaceDetector'; // tinyFaceDetector (190Kb) (quicker but less accurate)
//let nnUsed = 'ssdMobilenetv1'; // ssdMobilenetv1 (5.4Mb) (slower but more accurate)

// minConfidence/scoreThreshold ratio (default 0.5)
let scoreThresholdHaalia = 0.5; // default value 0.5

let happyTimeoutInMs=35000 ; // 35s - nb of ms to move to step2 even without smile

let facescanIntervalInMs = 1000; // 1s

// VideoMe - Webcam Video
// VideoMovie - User selected movie

// step0 - <p> text to indicate age detection loading
// step1 - <p> text to ask for smile
// step2 - <p> text select genre
// step2Select - <select> for genre

class TryMePage extends React.Component {

    intervalID = 0;
    smiletimeoutId = 0;
    countFaceNotDetectedInARow = 0;
    NNloaded = false;

    constructor() {
        super();
        this.state = {
            videoMeSrc: null,
            moviesList: [],
            selectedMovie: "not-selected",
            selectedMovieSrc: "",
            happyTripped: false,
            movieStarted: false,
            happyFaceDetected: false,
            gender: null,
            age: null,
            hideMovie: true,
            hidePlayBtn:false,
            hideMe: false,
            trackerId: null,
            happyTimeout: false,
            modalOpen: false,
            currentStep: -1,
            ended:false,
            isIos:false,
            filterVideos:''
        }
    }

    componentDidMount = async () => {
        document.title = i18n.t('page_title');

        this.NNloaded = false;
        Promise.all([
            (nnUsed === 'tinyFaceDetector' ?
                faceapi.nets.tinyFaceDetector.loadFromUri('/models') :
                faceapi.nets.ssdMobilenetv1.loadFromUri('/models')),

            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
            faceapi.nets.ageGenderNet.loadFromUri("/models")
        ])
            .then(async () => {
                console.log('TryMePage.componentDidMount() : NN models loaded successfully');
                this.NNloaded = true;
                this.getVideoList();
                window.scrollTo(0, 0); // when we moved from resultPage to survey we need to move from bottom to top screen
            })
            .catch(error => {
                console.error('TryMePage.componentDidMount() : NN models seems not loaded !',error);
                Sentry.captureException(error);
            });

        if (this.props.history && this.props.history.location && this.props.history.location.state)
            this.setState({ email: this.props.history.location.state.email });
        else {
            let storedEmail = localStorage.getItem('email');
            if(storedEmail !== null) {
                this.setState({ email: storedEmail });
            }
            else {
                document.location.href = "/"; // hack to WA none-react theme
            }
        }

        console.log('TryMePage.componentDidMount() : history state :',this.props.history.location.state);

        // if filterVideos exists
        if (this.props.history && this.props.history.location && this.props.history.location.state && typeof this.props.history.location.state.filterVideos != 'undefined') {
            this.setState({ filterVideos: this.props.history.location.state.filterVideos });
            console.log('TryMePage.componentDidMount() : filterVideos from props.history ['+this.props.history.location.state.filterVideos+']');
        }


        ReactGA.ga((tracker) => {
            this.setState({ trackerId: tracker.get('clientId') })
        });

        if(this.isIos()) {
            this.setState({ isIos: true });
        }


        let storedModalOpen = localStorage.getItem('modalOpen');
        if(storedModalOpen !== null && storedModalOpen === 'false') {
            console.log('TryMePage : modalOpen retrieved from localstorage ['+storedModalOpen+']');
            this.setState({ modalOpen: false });
            this.startVideoMe(); // start webcam
        }
        else {
            this.setState({ modalOpen: true });
        }
    }


    interpolateAgePredictions(age) {
        predictedAges = [age].concat(predictedAges).slice(0, 30);
        const avgPredictedAge =
            predictedAges.reduce((total, a) => total + a) / predictedAges.length;
        return avgPredictedAge;
    }

    componentWillUnmount() {
        this.stopTimerAndCam();
    }

    stopTimerAndCam = () => {
        clearInterval(this.intervalID);
        clearTimeout(this.smiletimeoutId);
        this.stopMeStream();
    }

    stopMeStream = () => {
        if(typeof meStream != 'undefined') {
            meStream.getTracks().forEach(function(track) {
                track.stop();
            });
        }
    }

    getVideoList = () => {
        let url =config.APIURL+"/getvideoslist?lang=" + i18n.language;

        // optional params (retrieved from Mainpage queryString f. Used in case we want to filter on limited or hidden videos from API. Used for private experimentations)
        if (this.state.filterVideos !='') {
            url += '&f='+this.state.filterVideos;
        }
        //console.log('will fetch videos list from ['+url+']');
        fetch(url,{headers:{'x-api-key': config.APIKEY}})
            .then((response) => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    moviesList: data
                });
            }).catch(error => {
                Sentry.captureException(error);
            });
    }

    handleHappyFaceDetected = (happyEmotion) => {
        if ((this.state.happyFaceDetected === false && (happyEmotion > 0.7 || this.state.happyTimeout))) {
            console.log('1st happy face detected');
            clearTimeout(this.smiletimeoutId);
            this.setState({ happyFaceDetected: true, currentStep: 2 });
        }
    }

    handleMovieSelect = (e) => {
        var selectedMovie = e.target.value
        var selectedMovieSrc = this.state.moviesList.find(x => x.name === selectedMovie).url
        this.videoMovie.src = selectedMovieSrc
        this.setState({ selectedMovie, selectedMovieSrc, hideMovie: false, hideMe: true, currentStep: 3 });
        this.videoMe.className = "offscreen"; // hack to still render video as its needed for webcam stream but offscreen
        this.overlayCanvas.className = "offscreen";

        this.resetLiveEmotionArray(); // to start fresh
        this.playVideoMovie();

        // if not iOS only.
        // if ios we have to follow https://webkit.org/blog/6784/new-video-policies-for-ios/
        // if(!this.state.isIos) {
        //     this.resetLiveEmotionArray(); // to start fresh
        //     this.playVideoMovie(); // start video
        // }
        // else {
        //     console.log('handleMovieSelect : for iOS device, we cannot auto play with sound. User has to click on play')
        // }
    }

    resetLiveEmotionArray = () => {
        // Reset emotion array to prevent pre-video emotions
        liveEmotions = {};
    }

     isIos() {
        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && // ipad
        !window.MSStream // to hack last windows Edge that containe "iPhone" (seems to hack some gmail restriction on edge)

        return isIOS;
      }

    startVideoMe = () => {

        this.setState({modalOpen: false, currentStep: 0});
        localStorage.setItem('modalOpen','false'); // to avoid asking again for this modal next time

        if(!this.state.isIos) {
            console.log('startVideoMe : We are not on iOS device');
            navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream) => this.setVideoMeStream(stream)).catch((err) => this.videoMeError(err))
        }
        else {
               console.log('startVideoMe : We are on iOS !!!');
               if(navigator.mediaDevices) {
                    var configIosCam = { video: { width: 640/*320-640-1280*/, facingMode:'user' } };
                    navigator.mediaDevices.getUserMedia(configIosCam).then(function(stream){
                          console.log('startVideoMe : cam loading OK');
                          this.videoMe.srcObject = stream;
                    }).catch(function(err) {
                        console.log('startVideoMe : cam loading KO',err)  ;
                        /* handle the error */
                    });
               }
                else {
                    alert('error iOS');
                    alert(i18n.t('permission_error'));
                    this.goToSample();
                }
        }
    }

    goToSample = () => {
        this.setState({modalOpen: false});
        localStorage.removeItem('modalOpen'); // do not keep it. Will open modal next time

        fetch(config.APIURL+"/getfeedbackresults?id=SAMPLE_DATA&lang=" + i18n.language,{headers:{'x-api-key': config.APIKEY}})
            .then((response) => {
                return response.json();
            })
            .then(data => {
                if (typeof data.feedback_id != 'undefined') {
                    data.userGanalyticsId = this.state.trackerId;
                    data.email = this.state.email;
                    console.log('trymePage : sample data retrieved from server', data)
                    this.props.history.push({ pathname: '/results', state: { data } });
                }
                else {
                    console.log('trymePage.goToSample() : KO. results data NOT loaded from backend', data);
                    document.location.href = "/"; // hack to WA none-react theme
                }
            }).catch(error => {
                Sentry.captureException(error);
                console.error('trymePage.goToSample(): cannot grab sample data from server');
                document.location.href = "/"; // hack to WA none-react theme
            });

    }

    setVideoMeStream = (stream) => {

        try {
        console.log('setVideoMeStream : videoMe.videoWidth',this.videoMe.videoWidth);
        meStream = stream;
        this.videoMe.srcObject = meStream;
        }
        catch(ex) {
            Sentry.captureException(ex);
        }
    }

    videoMeStarted = () => {

        const displaySize = { width: this.videoMe.offsetWidth, height: this.videoMe.offsetHeight }
        //const displaySize = { width: this.videoMe.videoWidth, height: this.videoMe.videoHeight }
        console.log('videoMeStarted : displaySize',displaySize);
        //this.overlayCanvas.style.top = '-'+this.videoMe.offsetHeight+'px';
        faceapi.matchDimensions(this.overlayCanvas, displaySize)


        this.smiletimeoutId = setTimeout(() => {
            console.log('happyTimeout thrown. No smile detected so far');
            this.setState({ happyTimeout: true });
        }, happyTimeoutInMs)

        this.intervalID = setInterval(async () => {
            try {

                const detectionOptions = nnUsed === 'tinyFaceDetector' ?
                    new faceapi.TinyFaceDetectorOptions({ scoreThreshold: scoreThresholdHaalia }) :
                    new faceapi.SsdMobilenetv1Options({ minConfidence: scoreThresholdHaalia });

                // check if models are loaded before caling detectFace()
                if(!this.NNloaded) {
                    console.log('videoMeStarted() : NN not loaded so far');
                }
                else {
                    // While we have not gathered age+gender OR not selected movie yet, we continue to grab age+gender (as interpolation help to increase age estimation acuracy)
                    //if (this.state.gender == null || this.state.selectedMovieSrc == "") {
                    if (this.state.gender == null) {
                        console.log(`withAgeAndGender() : will detect face withAgeAndGender() from NN [`+nnUsed+`[`);
                        faceapi.detectSingleFace(this.videoMe, detectionOptions).withAgeAndGender()
                            .then((initialDetection) => {
                                console.debug(`withAgeAndGender() : done`);
                                // if face is detected
                                if (initialDetection != null) {
                                    this.countFaceNotDetectedInARow=0;
                                    const age = initialDetection.age;
                                    const interpolatedAge = this.interpolateAgePredictions(age);
                                    const gender = initialDetection.gender;

                                    console.log(`withAgeAndGender() : Detected Age: ${interpolatedAge} and Gender: ${gender}`);
                                    this.setState({ age: interpolatedAge.toFixed(0), gender, currentStep: 1 })
                                }
                                else {
                                    this.countFaceNotDetectedInARow++;
                                    console.log(`withAgeAndGender() : Face not detected (age/gender not collected)`);
                                    if(this.countFaceNotDetectedInARow>6) {
                                        console.log(`withAgeAndGender() : It seems we cannot detect age+gender. Skip it and detect emotions`);
                                        Sentry.captureException(`withAgeAndGender() : It seems we cannot detect age+gender. Skip it and detect emotions`);
                                        this.stopTimerAndCam();
                                        alert(i18n.t('facedetector_error'));
                                        this.goToSample();
                                        //this.setState({ age: 0, gender: '', currentStep: 1 });
                                    }
                                }
                            })
                            .catch((error)=>{
                                    console.error('withAgeAndGender() : error while trying to detect face',error);
                                    Sentry.captureException(error);
                            });
                    }
                    else {
                        faceapi.detectSingleFace(this.videoMe, detectionOptions).withFaceExpressions()
                            .then((detections) => {
                            // if face is detected
                            if (detections != null) {
                                this.countFaceNotDetectedInARow = 0;

                                const resizedDetections = faceapi.resizeResults(detections, displaySize)

                                if (!this.state.movieStarted) {
                                    this.overlayCanvas.getContext('2d').clearRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height)
                                    faceapi.draw.drawDetections(this.overlayCanvas, resizedDetections)
                                    faceapi.draw.drawFaceExpressions(this.overlayCanvas, resizedDetections)
                                }

                                var detectionStats = {

                                    angry: detections.expressions.angry.toFixed(5),
                                    sad: detections.expressions.sad.toFixed(5),
                                    happy: detections.expressions.happy.toFixed(5),
                                    neutral: detections.expressions.neutral.toFixed(5),
                                    fearful: detections.expressions.fearful.toFixed(5),
                                    surprised: detections.expressions.surprised.toFixed(5),
                                    disgusted: detections.expressions.disgusted.toFixed(5),

                                };

                                this.handleHappyFaceDetected(detectionStats.happy);

                                let biggestEmotion = 'neutral';
                                let biggestEmotionVal = detectionStats.neutral;
                                if(detectionStats.angry>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.angry;
                                    biggestEmotion = 'angry';
                                }
                                if(detectionStats.sad>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.sad;
                                    biggestEmotion = 'sad';
                                }
                                if(detectionStats.happy>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.happy;
                                    biggestEmotion = 'happy';
                                }
                                if(detectionStats.fearful>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.fearful;
                                    biggestEmotion = 'fearful';
                                }
                                if(detectionStats.surprised>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.surprised;
                                    biggestEmotion = 'surprised';
                                }
                                if(detectionStats.disgusted>biggestEmotionVal) {
                                    biggestEmotionVal = detectionStats.disgusted;
                                    biggestEmotion = 'disgusted';
                                }
                                console.log('strongestEmotion['+biggestEmotion+']:'+biggestEmotionVal,detectionStats);

                                let tStamp = this.videoMovie.currentTime.toFixed(2).toString();
                                liveEmotions[tStamp] = detectionStats;

                                // if 1st time we are in this "else" after gathering age+gender
                                if(this.state.currentStep == 0) {
                                    this.setState({ currentStep: 1 }); // move to step1 (asking end user to smile)
                                }
                            }
                            else {
                                this.countFaceNotDetectedInARow++;
                                console.log(`withFaceExpressions() : Face not detected (emotions not collected)`);
                                if (!this.state.movieStarted && this.countFaceNotDetectedInARow>4) {
                                    this.overlayCanvas.getContext('2d').clearRect(0, 0, this.overlayCanvas.width, this.overlayCanvas.height)
                                    console.log(`withFaceExpressions() : User seems out of screen (emotions not collected)`);
                                }
                            }
                        })
                        .catch((error)=>{
                            console.error('withFaceExpressions() : error while trying to detect face',error);
                            Sentry.captureException(error);
                        });
                    }
                }
            }
            catch (err) {
                console.log(err);
                Sentry.captureException(err);
            }
        }, facescanIntervalInMs)
    }

    videoMeError = (exceptionModel) => {
        console.log('videoMeError : error while trying to load webcam');
        console.log(exceptionModel);
        Sentry.captureException(exceptionModel);
        alert(i18n.t('permission_error'));
        this.goToSample();
    }

    videoMovieEnded = () => {
        console.log('videoMovie ended OK');
        this.setState({ended: true});
        clearInterval(this.intervalID);
        this.stopMeStream();

        this.videoMe.srcObject = null;

        let submitData = {
            industry: this.state.selectedMovie,
            video: this.state.selectedMovieSrc,
            lang: i18n.language,
            email: this.state.email,
            videoDuration: this.videoMovie.duration,
            ageEstimation: this.state.age,
            genderEstimation: this.state.gender,
            emotions: liveEmotions,
            userGanalyticsId: this.state.trackerId,
            ended: false
        }

        fetch(config.APIURL+"/tryme", { method: 'POST', body: JSON.stringify(submitData),headers:{'x-api-key': config.APIKEY} })
            .then((response) => {
                return response.json();
            })
            .then(data => {
                console.log(data);
                data.userGanalyticsId = this.state.trackerId;
                data.email = this.state.email;
                this.props.history.push({ pathname: '/results', state: { data } });
            }).catch(error => {
                Sentry.captureException(error);
            });

    }

    // Hack to stop mac keyboard media API pausing video
    videoMePaused = (event) => { event.preventDefault(); this.videoMe.play(); };
    videoMoviePaused = (event) => { /*if(!this.state.ended) {event.preventDefault(); this.playVideoMovie();}*/ };

    playVideoMovie = () => {
        if(!this.state.ended) {
            this.videoMovie.play().then(() => {
                console.log('videoMovie play OK');
                this.videoMovie.muted = false;
                this.setState({ hidePlayBtn: true });
            }).catch((err) => {
                console.log('videoMovie play KO ',err);
            });
        }
    }

    render() {
        const movies = this.state.moviesList;
        return (
            <div style={{ backgroundColor: '#272727' }}>

                <Header />
                <section id="tryme" className="page-section bg-dark" style={{ minHeight: 1200 }}>

                    <div className="container">

                        <div id="trymeRowContainer" className="row" style={{ color: '#ddd'}}>

                            <div id="sideTextContainer" className="col-sm-4 col-lg-3 col-xs-12 text-left" hidden={this.state.modalOpen}>
                                <div>
                                    <h1>{i18n.t('tryitpage_cta')}</h1>

                                    <p>{i18n.t('tryitpage_subheading')}</p>


                                    {this.state.currentStep === 0 &&
                                        <div>
                                            <p style={{ fontWeight: 'bold' }}>{i18n.t('tryitpage_step0')} </p>
                                            <Loading />
                                        </div >
                                    }

                                    {this.state.currentStep === 1 && <div><div style={{width: 50, height: 50, backgroundColor: '#38b6ff', borderRadius: 90, display: 'inline-block', fontSize: 32, color: '#eee', textAlign: 'center', verticalAlign: 'middle' }}>1</div><p style={{display: 'inline', marginLeft: 20}}>{i18n.t('tryitpage_step1')}</p></div>}

                                    {this.state.currentStep === 2 && <div style={{ fontWeight: 'bold' }}><div style={{width: 50, height: 50, backgroundColor: '#38b6ff', borderRadius: 90, display: 'inline-block', fontSize: 32, color: '#eee', textAlign: 'center', verticalAlign: 'middle' }}>2</div><p style={{display: 'inline', marginLeft: 20}}> {i18n.t('tryitpage_step2')}</p>


                                        <select ref={step2Select => { this.step2Select = step2Select }} className="select-css" value={this.state.selectedMovie} onChange={this.handleMovieSelect}>
                                            <option value='not-selected' disabled>{i18n.t('Select a Genre')}</option>
                                            {movies.map((movie) => <option key={movie.name} value={movie.name}>{movie.displayName}</option>)}
                                        </select>
                                    </div>
                                    }
                                </div>
                            </div>

                            <div id="videosContainer" className="col-sm-8 col-lg-9 col-xs-12">
                                <div>
                                    <video ref={videoMe => { this.videoMe = videoMe }} onPause={this.videoMePaused} onPlay={this.videoMeStarted} id="videome" autoPlay loop muted disableremoteplayback="true" playsInline></video>
                                    <video ref={videoMovie => { this.videoMovie = videoMovie }} onPause={this.videoMoviePaused} onEnded={this.videoMovieEnded} id="videoMovie" hidden={this.state.hideMovie} style={{ maxWidth: '100%', zIndex: 100 }} disableremoteplayback="true" playsInline/>
                                    <button id="playVideoMovieBtn"  onClick={this.playVideoMovie} hidden={this.state.hideMovie || this.state.hidePlayBtn}><i className="fa fa-play"></i></button>
                                    <canvas ref={overlayCanvas => { this.overlayCanvas = overlayCanvas }} id='overlay' className="canvasOnScreen"></canvas>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


                <Modal show={this.state.modalOpen} animation={false} keyboard={false} backdrop={false} dialogClassName="permission-modal">
                    <Modal.Header>
                        <Modal.Title>{i18n.t('permission_modal_title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{i18n.t('permission_modal_desc')}</Modal.Body>
                    <Modal.Footer>
                        <Button className="denyCameraAccess" variant="secondary" onClick={this.goToSample}>
                            {i18n.t('permission_modal_no')}
                        </Button>
                        <Button  className="grantCameraAccess" variant="primary" onClick={this.startVideoMe}>
                            {i18n.t('permission_modal_yes')}
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>



        )

    }

}

export default withRouter(TryMePage);