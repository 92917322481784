import React from 'react';
import EmailCaptureForm from './EmailCaptureForm';
import i18n from "../i18n";

class HeroHeader extends React.Component {

    render() {
        const dataStrings = '["' + i18n.t("hero_type_1") + ' ^6000", "' + i18n.t("hero_type_2") + ' ^4000"]'

        return (

            <div>
                <video autoPlay muted loop id="fullscreen-video" >
                    <source src="/media/video.mp4" type='video/mp4; codecs="avc1.4D401E, mp4a.40.2"' />Your browser does not support the video tag. I suggest you upgrade your browser.
					<source src="/media/office-day.webm" type="video/webm" />Your browser does not support the video tag. I suggest you upgrade your browser.
				</video>
                <div className="poster hidden">
                    <img src="media/office-day.jpg" alt="" />
                </div>
                <div className="item-caption text-center">
                    <h1><span className="typing-text" data-strings={dataStrings}></span></h1>
                    <p className="lead mb-40">{i18n.t('hero_cta')}</p>

                    <EmailCaptureForm startDemo={this.props.startDemo} />

                </div>
            </div>



        )

    }

}

export default HeroHeader;