import React from 'react';
import config from '../config.json';
import i18n from '../i18n';
import { withRouter } from "react-router";
import * as Sentry from '@sentry/browser';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import Header from "../Components/Header";
import TreeMapLegend  from '../Components/TreeMapLegend'
import FeedbackRadialChart from "../Components/FeedbackRadialChart";
import { Treemap } from 'react-vis';
import { FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, HorizontalBarSeries, VerticalGridLines, LineSeries, MarkSeries, Hint, WhiskerSeries } from 'react-vis';
import EkgLegend  from '../Components/EkgLegend'
import Parser from 'html-react-parser';
import { crmService } from '../utils/crmService';

import ContactWidget from '../Components/ContactWidget'


const color_expectation = 'yellow'; //'#3a9277';
const color_positive = 'green';
const color_negative = 'red';
const color_neutral = '#4545a0';

const color_ekg = 'yellow';
const color_benchmark_ekg = 'lightgrey';
const color_expectedMomentumPoints = 'white';

class ResultsPage extends React.Component {

    constructor(props) {
        super(props);



        this.state = {
            results: {},
            emotionsResultPage: {},
            treeWidthSet: false,
            treeWidth: 0,
            hintVal: null
        }


        this.treemapDivContainer = React.createRef();

    }

    componentDidMount() {
        document.title = i18n.t('resultspage_title')+ ' - ' + i18n.t('page_title');
        crmService.trackPageView('/results');


        let historyState = this.props.history.location.state;

        // normal use case when we come from tryme page
        if (historyState !== undefined) {
            this.setState({
                results: historyState.data,
                emotionsResultPage: {}
            })
        }
        // else we are loading result page directly
        else {
            this.loadFeedbackResultsOrRedirectToHome();
        }


    }

    componentDidUpdate() {
        if(this.treemapDivContainer.current && !this.state.treeWidthSet) {
            this.setState({treeWidthSet:true, treeWidth: this.treemapDivContainer.current.offsetWidth})
        }
    }


    loadFeedbackResultsOrRedirectToHome = () => {

        const params = queryString.parse(this.props.location.search);
        if (typeof params.id == 'undefined' || typeof params.h == 'undefined') {
            //console.log('loadFeedbackResultsOrRedirectToHome() : KO. missing querystring url params', params);
            //document.location.href = "/"; // hack to WA none-react theme
            params.id='SAMPLE_DATA'; // by default, we load sample data
            params.h='';
        }

        fetch(config.APIURL+`/getfeedbackresults?id=${params.id}&h=${params.h}&lang=${i18n.language}`,{headers:{'x-api-key': config.APIKEY}})
            .then((response) => {
                return response.json();
            })
            .then(data => {

                if (typeof data.feedback_id != 'undefined') {
                    console.debug('loadFeedbackResultsOrRedirectToHome() : all good. results data loaded from backend', data);
                    let analyticsClientId = '';
                    ReactGA.ga((tracker) => {
                        analyticsClientId = tracker.get('clientId');
                    });
                    data.userGanalyticsId = analyticsClientId;
                    this.setState({
                        results: data,
                        emotionsResultPage: {}
                    });
                }
                else {
                    console.log('loadFeedbackResultsOrRedirectToHome() : KO. results data NOT loaded from backend', data);
                    document.location.href = "/"; // hack to WA none-react theme
                }
            }).catch(error => {
                Sentry.captureException(error);
                console.log('loadFeedbackResultsOrRedirectToHome() : KO. error returned by backend', error);
                document.location.href = "/"; // hack to WA none-react theme
            });
    }

    startSurvey = () => {
        let finalData = {
            tryMeStateData: this.state.results,
            emotionsResultPage: this.state.emotionsResultPage
            //questions: surveyStructure
        }
        this.props.history.push({ pathname: '/survey', state: finalData });
    }

    render() {
        const { results, treeWidth } = this.state;
        let hintDatapoint = null;// used to display hint
        return (

            <div style={{ backgroundColor: '#272727' }}>

                <Header showSurvey={this.startSurvey} />
                <section id="resultPage" className="page-section bg-dark">


                    {(results.analysisStatus === "DONE" || results.analysisStatus === "DONE_WITHOUT_WEBCAM") && (
                        <div id="resultPageContainer" className="container">

                            <div className="rowTitle row text-left">
                                <div className="col-sm-8">
                                    <h1 style={{ color: '#ddd' }}>{i18n.t('resultspage_title')}</h1>
                                    <p style={{ color: '#ddd' }}>{i18n.t('resultspage_subheading')}</p>
                                    <button type="button" className="btn btn-primary btn-block" style={{width: 250}}
                                        onClick={this.startSurvey}>{i18n.t('survey_button')}</button>
                                </div>
                                {( results.feedback_id == 'SAMPLE_DATA') && <div
                                    className="col-sm-4 sampleDataTitle text-right">
                                        <h2 style={{ color: '#ddd' }}>{i18n.t('resultspage_titleSampleData')}</h2>
                                    </div>
                                }
                            </div>


                            <div className="row rowRadialCharts" style={{ color: '#ddd', marginTop: 70}}>
                                <div className="col-sm-8 text-center">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_Feedback')}</h3>
                                    {( results.expectationFeedbackText != null && !(typeof (results.expectationFeedbackText.good) == 'undefined')) && <ul
                                        className="text-left" style={{ marginTop: 30 }}>
                                            {results.expectationFeedbackText.good.map(exp => {
                                                return <li className="feedback-bean feedback-good">{Parser(exp)}</li>
                                            })}
                                        </ul>
                                    }
                                    {( results.expectationFeedbackText != null && !(typeof (results.expectationFeedbackText.bad) == 'undefined')) && <ul
                                        className="text-left">
                                            {results.expectationFeedbackText.bad.map(exp => {
                                                return <li className="feedback-bean feedback-bad">{Parser(exp)}</li>
                                            })}
                                        </ul>
                                    }
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_ExpectationScore')}</h3>
                                    <FeedbackRadialChart displayPercent={results.expectationFeedbackPercent} displayColor={color_expectation} />
                                </div>
                            </div>


                            <div className="row rowRadialCharts" style={{ color: '#ddd'}}>
                                <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_EmotionDurationTitle')}</h3>
                            </div>
                            <div className="row" style={{ color: '#ddd', marginTop: 0 }}>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_PositiveSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalDurationPositiveEmotionsInPercent} displayColor={color_positive} />
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_NegativeSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalDurationNegativeEmotionsInPercent} displayColor={color_negative} />
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_NeutralSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalDurationMainEmotionInPercent_Neutral} displayColor={color_neutral} />
                                </div>
                            </div>

                            <div className="row rowRadialCharts" style={{ color: '#ddd'}}>
                                <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_EmotionIntensityTitle')}</h3>
                            </div>
                            <div className="row" style={{ color: '#ddd', marginTop: 0 }}>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_PositiveSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalIntensityPositiveEmotionsInPercent} displayColor={color_positive} />
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_NegativeSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalIntensityNegativeEmotionsInPercent} displayColor={color_negative} />
                                </div>
                                <div className="col-sm-4 text-center">
                                    <h4 style={{ color: '#ddd' }}>{i18n.t('resultspage_NeutralSentiment')}</h4>
                                    <FeedbackRadialChart displayPercent={results.totalIntensityMainEmotionInPercent_Neutral} displayColor={color_neutral} />
                                </div>
                            </div>



                            {!(typeof (results.emotionalCharts) == 'undefined') && !(typeof (results.emotionalCharts.dataEmogram) == 'undefined') && <div className="row rowEmotionalEKG" style={{ color: '#ddd' }}>
                                <div className="colEmotionalEKG col-sm-12 text-left">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_EmotionalEKG')}</h3>
                                    <div><FlexibleXYPlot
                                        height={300}>
                                        <LineGlow />
                                        <HorizontalGridLines tickValues={[0]} style={{ strokeWidth: '2px', stroke: '#FFFFFF' }} />
                                        <HorizontalGridLines tickValues={[-100, -50, -25, 0, 25, 50, 75, 100]} style={{ strokeWidth: '0.5px', stroke: '#09840b' }} />
                                        <VerticalGridLines tickTotal={15} style={{ strokeWidth: '0.5px', stroke: '#09840b' }} />

                                        {/* Current feedback : Emotion EKG line series. x=time, y= % of intensity per second (for each second we sum positive weights and negative weights. We pick up the bigger one. negative are *-1)*/}
                                        <LineSeries getNull={(d) => d.y !== null}
                                            color={color_ekg}
                                            data={this.state.results.emotionalCharts.dataEmogram}
                                            style={{ filter: "url(#glow)", fillOpacity: 0 }}
                                        />

                                        {/* Benchmark comparion (for given video, all feedbacks) : Emotion EKG line series. x=time, y= % of intensity per second (for each second we sum positive weights and negative weights. We pick up the bigger one. negative are *-1)*/}
                                        {!(typeof (results.benchmarkForGivenVideo.emotionalCharts) == 'undefined') && <LineSeries
                                            getNull={(d) => d.y !== null}
                                            color={color_benchmark_ekg}
                                            strokeStyle="dashed"
                                            style={{ filter: "url(#glow)", fillOpacity: 0 }}
                                            data={results.benchmarkForGivenVideo.emotionalCharts.dataEmogram} />
                                        }

                                        {/* Expected momentum "start" point for x. for y, if expecting positive momentum, will be put in threshold for matching momemntum. If negative momentum, same but *-1. If neutral y=0 */}
                                        {!(typeof (results.emotionalCharts.dataMarkSeriesExpectedMomentum) == 'undefined') && <MarkSeries
                                            color={color_expectedMomentumPoints}
                                            data={results.emotionalCharts.dataMarkSeriesExpectedMomentum}
                                            onValueMouseOver={(datapoint, event) => {
                                                this.setState({hintVal: datapoint});
                                            }}
                                            onValueMouseOut={(datapoint, event) => {
                                                this.setState({hintVal: null});
                                            }}
                                        />
                                        }

                                        {/* Expected momentum in whisker start-end segments.*/}
                                        {/* Luc's comment for James : It seems that Xsccale is not OK. I don't know why */}

                                    {/* {!(typeof(results.emotionalCharts.dataWhiskerSeriesExpectedMomentum)=='undefined' ) && <WhiskerSeries
                                            color="white"
                                            strokeWidth={2}
                                            data={results.emotionalCharts.dataWhiskerSeriesExpectedMomentum}
                                        />
                                    } */}


                                        <XAxis
                                            tickValues={results.emotionalCharts.tickValuesX}
                                            tickFormat={function tickFormat(d) {
                                                const date = new Date(d * 1000)// js timestamp are in ms
                                                return date.toISOString().substr(14, 5); // ii:ss
                                            }} />
                                        <YAxis tickValues={[-100, -50, -25, 0, 25, 50, 75, 100]} height={300} title={i18n.t('resultspage_EKG_YaxisTitle')} />

                                        {this.state.hintVal && <Hint
                                            value={this.state.hintVal}>
                                            <div className="rv-hint__content">
                                                {!(typeof (results.emotionalCharts.dataMarkSeriesExpectedMomentumLabels) == 'undefined') && !(typeof (results.emotionalCharts.dataMarkSeriesExpectedMomentumLabels[this.state.hintVal.x]) == 'undefined') && <p>
                                                    {results.emotionalCharts.dataMarkSeriesExpectedMomentumLabels[this.state.hintVal.x]}
                                                </p>
                                                }
                                            </div>
                                        </Hint>
                                        }


                                    </FlexibleXYPlot>
                                    <EkgLegend />                                    
                                    </div>
                                    

                                </div>
                            </div>
                            }

                            {!(typeof (results.emotionalCharts) == 'undefined') && (!(typeof (results.emotionalCharts.dataHappy) == 'undefined') || !(typeof (results.emotionalCharts.dataSad) == 'undefined') || !(typeof (results.emotionalCharts.dataSurprised) == 'undefined') || !(typeof (results.emotionalCharts.dataAngry) == 'undefined') || !(typeof (results.emotionalCharts.dataDisgusted) == 'undefined') || !(typeof (results.emotionalCharts.dataFearful) == 'undefined')) && <div className="row rowEmotionalEKG" style={{ color: '#ddd' }}>
                                <div className="colEmotionalEKG col-sm-12 text-left">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_EmotionalEKGPerEmotion')}</h3>
                                     <div><FlexibleXYPlot
                                        height={300}>
                                        <LineGlow />                                        
                                        <HorizontalGridLines tickValues={[-100, -50, -25, 0, 25, 50, 75, 100]} style={{ strokeWidth: '0.5px', stroke: '#09840b' }} />
                                        <VerticalGridLines tickTotal={15} style={{ strokeWidth: '0.5px', stroke: '#09840b' }} />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color={color_positive}
                                            data={this.state.results.emotionalCharts.dataHappy}
                                        />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color={color_negative}
                                            data={this.state.results.emotionalCharts.dataSad}
                                        />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color="orange"
                                            data={this.state.results.emotionalCharts.dataSurprised}
                                        />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color="hotpink"
                                            data={this.state.results.emotionalCharts.dataFearful}
                                        />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color="darkred"
                                            data={this.state.results.emotionalCharts.dataAngry}                                            
                                        />

                                        <LineSeries getNull={(d) => d.y !== null}
                                            color="purple"
                                            data={this.state.results.emotionalCharts.dataDisgusted}                                            
                                        />                                        
                                        
                                        <HorizontalGridLines tickValues={[0]} style={{ strokeWidth: '3px', stroke: '#FFFFFF' }} />

                                        <XAxis
                                            tickValues={results.emotionalCharts.tickValuesX}
                                            tickFormat={function tickFormat(d) {
                                                const date = new Date(d * 1000)// js timestamp are in ms
                                                return date.toISOString().substr(14, 5); // ii:ss
                                            }} />
                                        <YAxis tickValues={[-100, -50, -25, 0, 25, 50, 75, 100]} height={300} title={i18n.t('resultspage_EKG_YaxisTitle')} />

                                        </FlexibleXYPlot>  
                                        <TreeMapLegend />                                  
                                        </div>
                                    </div>
                                </div>
                                }



                            <div className="row rowEmotionalExperience" ref={this.treemapDivContainer} style={{ color: '#ddd' }}>
                                <div className="col-sm-12 text-left">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_EmotionalExperience')}</h3>
                                    {!(typeof (this.state.treeWidthSet) == 'undefined') && this.state.treeWidthSet && <Treemap
                                        {...{
                                            hideRootNode: true,
                                            animation: true,
                                            className: 'nested-tree-example',
                                            colorType: 'literal',
                                            data: {
                                                "title": "master", "color": "#12939A",
                                                children: [
                                                    { "title": i18n.t('emotion_happy')+' - '+results.totalDurationMainEmotionInPercent_Happy+'%', "color": color_positive, "size": results.totalDurationMainEmotionInPercent_Happy },
                                                    { "title": i18n.t('emotion_surprised')+' - '+results.totalDurationMainEmotionInPercent_Surprised+'%', "color": "orange", "size": results.totalDurationMainEmotionInPercent_Surprised },
                                                    { "title": i18n.t('emotion_sad')+' - '+results.totalDurationMainEmotionInPercent_Sad+'%', "color": color_negative, "size": results.totalDurationMainEmotionInPercent_Sad },
                                                    { "title": i18n.t('emotion_angry')+' - '+results.totalDurationMainEmotionInPercent_Angry+'%', "color": "darkred", "size": results.totalDurationMainEmotionInPercent_Angry },
                                                    { "title": i18n.t('emotion_disgusted')+' - '+results.totalDurationMainEmotionInPercent_Disgusted+'%', "color": "purple", "size": results.totalDurationMainEmotionInPercent_Disgusted },
                                                    { "title": i18n.t('emotion_fearful')+' - '+results.totalDurationMainEmotionInPercent_Fearful+'%', "color": "hotpink", "size": results.totalDurationMainEmotionInPercent_Fearful },
                                                    { "title": i18n.t('emotion_neutral')+' - '+results.totalDurationMainEmotionInPercent_Neutral+'%', "color": color_neutral, "size": results.totalDurationMainEmotionInPercent_Neutral },
                                                ]
                                            },
                                            mode: 'squarify',
                                            renderMode: 'dom',
                                            height: 350,
                                            width: treeWidth,
                                            margin: 10
                                        }} /> }
                                    <TreeMapLegend 
                                        happy={results.totalDurationMainEmotionInPercent_Happy}
                                        surprised={results.totalDurationMainEmotionInPercent_Surprised}
                                        sad={results.totalDurationMainEmotionInPercent_Sad}
                                        angry={results.totalDurationMainEmotionInPercent_Angry}
                                        disgusted={results.totalDurationMainEmotionInPercent_Disgusted}
                                        fearful={results.totalDurationMainEmotionInPercent_Fearful}
                                        neutral={results.totalDurationMainEmotionInPercent_Neutral}
                                    />
                                </div>


                            </div>

                            <div className="row rowAgeAndGender" style={{ color: '#ddd', marginTop: 50 }}>
                                <div className="colAgeAndGender col-sm-12 text-left">
                                    <h3 style={{ color: '#ddd' }}>{i18n.t('resultspage_AgeAndGender')}</h3>
                                </div>

                                <div className="colAgeAndGender maleChartContainer col-sm-3 text-center"  style={{color:'#FF1F98'}}>
                                    <div className="fa fa-female fa-3x"></div>
                                    <div className="femaleChart">
                                    <FeedbackRadialChart displayPercent={results.benchmarkForGivenVideo.ageAndGenderData.femalePercent} displayColor="#FF1F98" />
                                    </div>
                                    <div className="femaleSubtitle">{i18n.t('resultspage_AgeAndGender_female')}</div>
                                </div>
                                <div className="colAgeAndGender femaleChartContainer col-sm-3 text-center" style={{color:'#00A0FF'}}>
                                    <div className="fa fa-male fa-3x"></div>
                                    <div className="maleChart">
                                        <FeedbackRadialChart displayPercent={results.benchmarkForGivenVideo.ageAndGenderData.malePercent} displayColor="#00A0FF" />
                                    </div>
                                    <div className="maleSubtitle">{i18n.t('resultspage_AgeAndGender_male')}</div>
                                </div>
                                <div className="colAgeAndGender col-sm-6 text-left">
                                    {!(typeof (results.benchmarkForGivenVideo.ageAndGenderData) == 'undefined') && <div><FlexibleXYPlot
                                        height={400}>
                                        <LineGlow />
                                        <VerticalGridLines tickValues={[0, 25, 50, 75,  100]} style={{ strokeWidth: '0.5px', stroke: '#09840b' }} />

                                        {/* Benchmark comparion (for given video, all feedbacks) : Emotion EKG line series. x=time, y= % of intensity per second (for each second we sum positive weights and negative weights. We pick up the bigger one. negative are *-1)*/}
                                        {!(typeof (results.benchmarkForGivenVideo.ageAndGenderData) == 'undefined') && <HorizontalBarSeries
                                                getNull={(d) => d.y !== null}
                                                style={{ filter: "url(#glow)" }}
                                                barWidth={0.5}
                                                data={results.benchmarkForGivenVideo.ageAndGenderData.HorizontalBarSeries.data}
                                            />
                                        }

                                        <YAxis
                                                style={{
                                                    text: {fill: '#ddd', fontSize:"9px"}
                                                }}
                                                tickValues={results.benchmarkForGivenVideo.ageAndGenderData.HorizontalBarSeries.tickValues}
                                                tickFormat={function tickFormat(k) {
                                                    return results.benchmarkForGivenVideo.ageAndGenderData.HorizontalBarSeries.tickLabels[k];
                                                }}
                                        />
                                        <XAxis tickValues={[0, 25, 50, 75, 100]} tickFormat={function tickFormat(k) {
                                                return k+'%';
                                        }}/>
                                       </FlexibleXYPlot>
                                    </div>
                                    }

                                </div>
                            </div>

                            {/*{!(typeof(this.state.results.resultBuiltFromSource)=='undefined' || this.state.results.resultBuiltFromSource=='other') && <div */}
                            <div
                                className="row rowSurveyButton" style={{ color: '#ddd'}}>
                                <div className="col-sm-12 text-center">
                                    <h2 style={{ color: '#ddd' }}>{i18n.t('survey_header')} </h2>
                                    <p>{i18n.t('survey_description')}

                                    </p>
                                    <button type="button" className="btn btn-primary btn-block btn-lg"
                                        onClick={this.startSurvey}>{i18n.t('survey_button')}</button>
                                </div>
                            </div>


                    </div>
                    )}
                </section>
                <ContactWidget />
            </div >



        )

    }

}

export default withRouter(ResultsPage);


export class LineGlow extends React.Component {
    render() {
        return (
            <defs>
                <filter id="glow">
                    <feFlood flood-color="rgb(255,255,128)" flood-opacity="0.2" in="SourceGraphic" />
                    <feComposite operator="in" in2="SourceGraphic" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComponentTransfer result="glow1">
                        <feFuncA type="linear" slope="4" intercept="0" />
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode in="glow1" />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
        );
    }
}
LineGlow.requiresSVG = true;