import React from 'react';
import config from '../config.json';
import i18n from "../i18n";
import { crmService } from '../utils/crmService';

class ContactWidget extends React.Component {

    constructor() {
        super();
        this.state = {
            email: '',
            message: '',
            name: '',
            submitted: false
        }
    }

    handleChangeEmail = (event) => this.setState({ email: event.target.value });
    handleChangeMessage = (event) => this.setState({ message: event.target.value });
    handleChangeName = (event) => this.setState({ name: event.target.value });


    submitContact = () => {
        if(this.state.email != '') {
        fetch(config.APIURL+"/contact", { method: 'POST', body: JSON.stringify(this.state), headers:{'x-api-key': config.APIKEY} })
            .then((response) => {
                this.setState({name: '', email: '', message: '', submitted: true})
            })
        }
    }

    contactAgain = () => this.setState({submitted: false})

    render() {

        return (

            <div className="contact-widget hidden-xs">
                <div className="panel panel-default">
                    <div className="panel-heading"><h3 className="panel-title">{i18n.t('message_us')}</h3></div>
                    <div className="panel-body">
                        <p>{i18n.t('message_us_description')}</p>


                        {!this.state.submitted ? (
                        <div id="contact-widget-form" >
                            <div id="msg-info-contact-widget" className="msg-info alert alert-danger" role="alert"></div>
                            <div className="form-group">
                                <input name="name" id="name-contact" type="text" className="form-control" placeholder={i18n.t('name')} onChange={this.handleChangeName} />
                            </div>
                            <div className="form-group">
                                <input name="email" id="email-contact" type="email" className="form-control" placeholder={i18n.t('email')} onChange={this.handleChangeEmail}/>
                            </div>
                            <div className="form-group">
                                <textarea name="message" id="message-contact" className="form-control" rows="8" placeholder={i18n.t('message')} onChange={this.handleChangeMessage}></textarea>
                            </div>
                            <input type="button" className="btn btn-primary pull-right " value={i18n.t('submit')} id="submit-msg-contact" onClick={this.submitContact} />
                            <div className="clearfix"></div>
                        </div>
                        ) : (
                            <div id="contact-widget-form" style={{fontSize: 20}} >
                                <b>{i18n.t('contact_thanks')}</b>
                                <input type="button" className="btn btn-primary pull-right " value={i18n.t('contact_again')} id="submit-msg-contact" style={{marginTop: 100}} onClick={this.contactAgain} />
                            </div>
                        )}



                    </div>
                </div>

                <div className="contact-widget-btn" title={i18n.t('message_us')}>
                    <i className="fa fa-comments-o"></i>
                </div>

            </div>


        )

    }

}

export default ContactWidget;

















