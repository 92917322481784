import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AnalyticsListener from './utils/AnalyticsListener'
import { createBrowserHistory } from "history";




import MainPage from './Pages/MainPage';
import TryMePage from './Pages/TryMePage';
import ResultsPage from './Pages/ResultsPage';
import SurveyPage from './Pages/SurveyPage';
import ThanksPage from './Pages/ThanksPage';

const history = createBrowserHistory();


function App() {
  return (
    <Router history={history}>
      <AnalyticsListener history={history}>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/tryme">
          <TryMePage />
        </Route>
        <Route path="/results">
          <ResultsPage />
        </Route>
        <Route path="/survey">
          <SurveyPage />
        </Route>
        <Route path="/thanks">
          <ThanksPage />
        </Route>
      </Switch>
      </AnalyticsListener>
    </Router>
  );
}

export default App;
