import React from 'react';
import { RadialChart } from 'react-vis';


class FeedbackRadialChart extends React.Component {

    render() {

        return (

            <div>
            <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 30
            }}>
            <RadialChart
                className={'donut-chart-example'}
                innerRadius={80}
                radius={120}
                getAngle={d => d.theta}
                data={[{ theta: this.props.displayPercent, color:this.props.displayColor },
                { theta: 100 - this.props.displayPercent, color: '#444444' }]}
                width={240}
                height={240}
                padAngle={0.04}
                colorType="literal"
                center={{ x: 0, y: 0 }}

            />
            <div style={{
                position: 'absolute',
                padding: '5px',
                fontSize: 30,
                fontWeight:'bold',
                color: '#3a9277'
            }}>
                {this.props.displayPercent + " %"}
        </div>
        </div>

        </div>

        )

    }

}

export default FeedbackRadialChart;




