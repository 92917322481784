import React from 'react';
import i18n from "../i18n";

class Header extends React.Component {

	storedEmail = '';
	componentDidMount() {
		this.storedEmail = localStorage.getItem('email');
        if(this.storedEmail !== null && window.location.pathname!=='/tryme') {
            console.log('Header : email retrieved from localstorage ['+this.storedEmail+']');
		}
		else {
			this.storedEmail = '';
		}
    }
    render() {

        return (

            <nav className="navbar navbar-default navbar-fixed-top main-nav">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="navbar-header page-scroll">
							<button type="button" className="navbar-toggle" data-toggle="collapse"
								data-target=".navbar-ex1-collapse">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<a href="/" className="navbar-left">
								<img src="img/logo-light.png" className="logo logo-light" alt="Haalia AI" width="170"
									height="50" />
								<img src="img/logo-dark_orange-black.png" className="logo logo-dark" alt="Haalia AI" width="170"
									height="50"/>
							</a>
						</div>
						<div className="collapse navbar-collapse navbar-ex1-collapse">
							<ul className="nav navbar-nav navbar-right main-menu">
								<li className="active hidden">
									{
									//eslint-disable-next-line
									}<a className="page-scroll" href="#page-top"></a>
								</li>
								<li>
									<a className="page-scroll" href="/">{i18n.t('nav_home')}</a>
								</li>
								{this.storedEmail!=='' && <li>
										<a className="page-scroll topBarTryMeAgain" href="/tryme">{i18n.t('thankspage_gotryme')}</a>
									</li>
								}
								{this.props.showSurvey && <li>
										<a className="page-scroll topBarTryMeAgain" href="" onClick={this.props.showSurvey}>{i18n.t('share_feedback')}</a>
									</li>
								}

							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>



        )

    }

}

export default Header;














