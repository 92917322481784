import React from 'react';
import config from '../config.json';
import i18n from "../i18n";
import ReactGA from 'react-ga';
import { crmService } from '../utils/crmService';


class EmailCaptureForm extends React.Component {

    emailRetrievedFromLocalstorage = false;
    constructor() {
        super();
        this.state = {
            hasEnteredEmail: false,
            email: '',
            targettingCategory: '',
            error: '',
            accepted: false,
        }

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }


    componentDidMount() {
        let storedEmail = localStorage.getItem('email');
        if(storedEmail !== null) {
            console.log('EmailCaptureForm : email retrieved from localstorage ['+storedEmail+']');
            this.setState({'email': storedEmail, 'accepted':true}); // if set into localstorage, we have accepted privacy policy previously
            this.emailRetrievedFromLocalstorage = true;
        }
    }
    startTryIt = () => {

        if (this.state.email.length < 5 || this.state.email.indexOf("@") === -1 || this.state.email.indexOf(".") === -1) {
            this.setState({ error: i18n.t('Please enter a valid email address') });
            return;
        }

        if(!this.state.accepted) {
            this.setState({ error: i18n.t('Please accept the terms and conditions of the privacy policy') });
            return;
        }
        localStorage.setItem('email', this.state.email);

        let analyticsClientId = '';
        ReactGA.ga((tracker) => {
            analyticsClientId = tracker.get('clientId');
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','x-api-key': config.APIKEY },
            body: JSON.stringify({ email: this.state.email, lang: i18n.language, userGanalyticsId: analyticsClientId })
        };
        fetch(config.APIURL+'/tryme', requestOptions)
            .then(response => response.json())
            .then(async data => {
                await crmService.saveCaptureEmailForm(this.state.email);

                this.setState({ hasEnteredEmail: true, error: '' });
                this.props.startDemo(this.state.email);
            });
    }

    handleChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    }


    handleChangeChecked = (e) => {
        this.setState({ accepted: e.target.checked })
    }

    inputKey=(event)=> {
        if (event.keyCode === 13) {
            this.startTryIt();
        }
    }

    render() {
        const hasEnteredEmail = this.state.hasEnteredEmail;

        return (
            <div>
                {hasEnteredEmail ? (<div>

                    </div>
                )
                    :
                    ( !this.emailRetrievedFromLocalstorage?(
                            <div id="homeEmailCaptureForm">
                                <input id="email" className="enjoy-css" type="email" name="email" autoComplete="off" onKeyDown={(e) => this.inputKey(e) } placeholder={i18n.t('cta_email')} value={this.state.email} onChange={this.handleChangeEmail} />
                                <button className="btn btn-primary btn-shadow btn-l page-scroll ml-10" style={styles.tryItButton} onClick={this.startTryIt}>{i18n.t('cta_email_button')}</button>
                                <div className="form-check" style={{ verticalAlign: 'top'}}>
                                    <input className="homePermissionCheckboxInput form-check-input form-radio" type="checkbox" onChange={this.handleChangeChecked} name="terms-accept" id="terms-accept" value={this.state.accepted} checked={this.state.accepted} style={{ verticalAlign: 'top'}} />
                                    <label className="form-check-label" htmlFor="terms-accept">
                                        {i18n.t('email_check_accept')} <a href="haalia-privacy-policy.pdf" target="_blank">{i18n.t('footer_privacy')}</a>
                                    </label>
                                </div>
                                <p style={styles.error}>{this.state.error}</p>
                            </div>
                        )
                        :
                        (
                        <button className="btn btn-primary btn-shadow btn-l page-scroll ml-12" style={styles.tryItButton} onClick={this.startTryIt}>{i18n.t('cta_emailTryAgain_button')}</button>
                        )
                    )
                }

            </div>

        )

    }


}


const styles = {
    tryItButton: {
        padding: 14,
        paddingLeft: 30,
        paddingRight: 30,
        fontWeight: 'bold'
    },
    error: {
        color: 'red',
        fontWeight: 'bold'
    }
}

export default EmailCaptureForm;


