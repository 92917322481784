
import React from 'react';

class Loading extends React.Component {

    render() {
        return (
            <div className="loadersp">Loading...</div>
        )

    }

}

export default Loading;