import React, { Component } from 'react'
import PropTypes from 'prop-types'

var _hsq = window._hsq = window._hsq || [];

class AnalyticsListener extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.props.history.location);
    this.props.history.listen(this.sendPageView);


  }



  sendPageView(location) {
    //_hsq.push(['setPath', location.pathname]);
    //_hsq.push(['trackPageView']);
  }

  render() {
    return this.props.children;
  }
}

export default AnalyticsListener