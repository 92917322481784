import React from 'react';
import i18n from '../i18n';

import { GradientDefs, DiscreteColorLegend } from 'react-vis';
import i18next from 'i18next';

const ITEMS = [
  {title: i18n.t('Current EKG'), color: "yellow", },
  {title: i18n.t('Benchmark'), color: 'lightgrey', strokeStyle: "dashed"},
  {title: i18n.t('Expected Emotional Momentum'), color: 'url(#circles)', strokeWidth: 10},
];

export default function DiscreteColorExample() {
  return (
    <div className="legendContainer">
      <svg height={0} width={0}>
        <GradientDefs>
            <pattern id="circles" width="7" height="7" patternUnits="userSpaceOnUse">
              <circle cx="1.5" cy="1.5" r="0.9" fill="white" />
            </pattern>

        </GradientDefs>
      </svg>
    <DiscreteColorLegend orientation="horizontal"  items={ITEMS} />
    </div>
  );
}