/* eslint jsx-a11y/anchor-is-valid: "off", react/jsx-no-target-blank: "error" */
import React from 'react';
import config from '../config.json';
import HeroHeader from '../Components/HeroHeader';
import i18n from "../i18n";
import MainNavigation from '../Components/MainNavigation';
import queryString from 'query-string';
import { withRouter } from "react-router";
import LoadingOverlay from "../Components/LoadingOverlay";
import * as Sentry from '@sentry/browser';
import Odometer from 'react-odometerjs';
import Parser from 'html-react-parser';
import VizSensor from 'react-visibility-sensor';
import ContactWidget from '../Components/ContactWidget'
import { crmService } from '../utils/crmService';

class MainPage extends React.Component {

    constructor() {
        super();
        this.state = {
            isInDemo: false,
            filterVideos:'',
            emotionsStatistics:{},
            odometerValue_SumPositiveEmotionsInSec:0,
            odometerValue_CountFaceDatapointsAnalyzed:0,
            proto3_1stImg:'' // picked up randomly as I was hesitating between 2
        }
    }

    componentDidMount = () => {
        document.title = i18n.t('page_title');
        this.getEmotionsStatistics();
        if(Math.random()>=0.5)
            this.setState({proto3_1stImg:'img/proto3-speaker.jpg'});
        else
            this.setState({proto3_1stImg:'img/proto3-zoom.jpg'});
    }

    startDemo = (email) => {
        console.log(email)
        this.setState({ isInDemo: true });
        let stateData = {email: email};
        if (this.state.filterVideos != '') {
            stateData = {email: email, filterVideos: this.state.filterVideos};
        }
        else if (this.state.filterVideos !== '') {
            stateData = {email: email, filterVideos: this.state.filterVideos};
        }
        this.props.history.push({ pathname: '/tryme', state: stateData });
    }

    getEmotionsStatistics = () => {
        let url =config.APIURL+"/getemotionsstat";

        const params = queryString.parse(this.props.location.search);
        if (typeof params.f != 'undefined') {
            this.setState ({filterVideos: params.f});
            url += '?f='+params.f
        }
        else if (this.state.filterVideos != '') {
            url += '?f='+this.state.filterVideos;
        }
        //console.log('will fetch videos list from ['+url+']');
        fetch(url,{cache: "no-cache",headers:{'x-api-key': config.APIKEY}})
            .then((response) => {
                return response.json();
            })
            .then(data => {
                this.setState({
                    emotionsStatistics: data
                });
            }).catch(error => {
                Sentry.captureException(error);
            });
    }

    odometerVizSensorChanged = (isVisible) => {
        if(isVisible && this.state.odometerValue_SumPositiveEmotionsInSec == 0 && typeof this.state.emotionsStatistics.sumPositiveEmotionsInSec != 'undefined') {
            console.log('odometerVizSensorChanged : animate odometerValue');
            this.setState({
                odometerValue_SumPositiveEmotionsInSec: this.state.emotionsStatistics.sumPositiveEmotionsInSec,
                odometerValue_CountFaceDatapointsAnalyzed: this.state.emotionsStatistics.countFaceDatapointsAnalyzed,
            });

        }
    }

    render() {
        return (
            <div>
                <LoadingOverlay />
                <MainNavigation />
                <section id="home">
                    <div id="hero-video" className="">
                        <div className="item video-container video-container-main">
                            <div className="overlay overlay-secondary"></div>

                            <HeroHeader startDemo={this.startDemo} />

                        </div>
                    </div>
                    <div className="go-down-icon">
                        <a href="#intro" className="page-scroll"><i className="fa fa-angle-double-down"></i></a>
                    </div>
                </section>

                <section id="intro" className="page-section bg-light">
                    <div className="container">
                        <div className="row text-center">
                            <header className="col-md-10 col-md-offset-1 col-sm-12 section-heading sr-bottom">
                                <span className="sub-heading">{i18n.t('who_title')}</span>
                                <h2>{i18n.t('welcome_to')}<span className="text-">Haalia AI</span></h2>
                                <hr className="section-hr" />
                            </header>

                            <div className="col-md-10 col-md-offset-1 col-sm-12 section-content">
                                <p className="sub-heading lead mb-40 sr-bottom">{i18n.t("what_we_do")}</p>
                                <p className="sub-heading lead mb-40 sr-bottom">{i18n.t("what_we_do2")}</p>
                                <p className="sub-heading lead mb-40 sr-bottom">{i18n.t("what_we_do3")}</p>
                                <p className="sub-heading lead mb-40 sr-bottom">{i18n.t("what_we_do4")}</p>
                            </div>
                            <div className="col-md-12 col-sm-12 section-content">
                                <div className="row mb-20">
                                    {/* <div className="col-md-3 col-sm-3 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto1.jpg" className="homeImgClick_MovieTheaterFaces vb-lightbox"><img src="img/proto1.jpg" data-src="img/proto1.jpg" width="300" height="200" alt="intro 1" className="lazy-load rounded small" /></a>
                                    </div> */}
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto4.jpg" className="homeImgClick_Device vb-lightbox"><img src="img/proto4.jpg" data-src="img/proto4.jpg" width="300" height="200" alt="intro 2" className="lazy-load rounded small" /></a>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto3.jpg" className="homeImgClick_FacePoints vb-lightbox"><img src="img/proto3.jpg" data-src="img/proto3.jpg" width="300" height="200" alt="intro 3" className="lazy-load rounded small" /></a>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto2.jpg" className="homeImgClick_Charts vb-lightbox"><img src="img/proto2.jpg" data-src="img/proto2.jpg" width="300" height="200" alt="intro 3" className="lazy-load rounded small" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="segmenter" className="segmenter-container">
                    <div className="overlay overlay-secondary"></div>
                    <div className="segmenter vh_height50" style={{ backgroundImage: 'url(/img/steps.jpg)' }}></div>
                    <h2 className="trigger-headline trigger-headline--hidden">
                        <span>H</span>
                        <span>A</span>
                        <span>A</span>
                        <span>L</span>
                        <span>I</span>
                        <span>A</span>
                        <span>.</span>

                    </h2>
                </section>


                <section id="services" className="page-section bg-gray">
                    <div className="container">
                        <div className="row text-center">

                            <header className="col-md-10 col-md-offset-1 col-sm-12 section-heading heading-circle primary-bg sr-top">
                                <span className="sub-heading">{i18n.t("offer_section_title")}</span>
                                <h2>{i18n.t("offer_section_subtitle")}</h2>
                                <hr className="section-hr" />
                            </header>
                        </div>
                        <div className="row text-center">

                            <div className="col-sm-4 col-xs-12 mb-30 section-content sr-left">
                                <div className="icon-box hover-color shadow">
                                    <i className="icon fa fa-envelope-open-o"></i>
                                    <h3>{i18n.t("offer_section_box1_title")}</h3>
                                    <p>{i18n.t("offer_section_box1_description")}  </p>
                                    <a href="#" className="homeLearnMore_PlugAndPlayDevice btn btn-primary">{i18n.t("learn_more")}</a>
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12 mb-30 section-content sr-left">
                                <div className="icon-box hover-color shadow">
                                    <i className="icon fa fa-edit"></i>
                                    <h3>{i18n.t("offer_section_box2_title")}</h3>
                                    <p>{i18n.t("offer_section_box2_description")}  </p>
                                    <a href="#" className="homeLearnMore_HighQualityReport learn-more btn btn-primary">{i18n.t("learn_more")}</a>
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12 mb-30 section-content sr-left">
                                <div className="icon-box hover-color shadow">
                                    <i className="icon fa fa-bell-o"></i>
                                    <h3>{i18n.t("offer_section_box3_title")}</h3>
                                    <p>{i18n.t("offer_section_box3_description")}  </p>
                                    <a href="#" className="homeLearnMore_PredictiveAnalysis learn-more btn btn-primary">{i18n.t("learn_more")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="page-section container-wide dark-bg parallax" data-parallax="scroll" data-image-src="img/personsitting.jpg">
                    <div className="overlay overlay-secondary"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 cta-text text-center mb-10 sr-bottom">
                                <h2>{i18n.t('second_cta_header')}</h2>
                            </div>
                            <div className="col-md-12 col-sm-12 cta-btn text-center width-100 sr-bottom">
                                <a href="#" className="homeStartToday btn btn-primary btn-embossed btn-xl">{i18n.t("second_cta_click")} <i className="fa fa-shopping-bag"></i></a>
                            </div>
                        </div>
                    </div>
                </div>


                <section id="team" className="page-section bg-light">
                    <div className="container">
                        <div className="row mb-30">
                            <header className="col-md-12 col-sm-12 section-heading heading-left">
                                <hr className="section-hr" />
                                <span className="sub-heading">{i18n.t("team_header")}</span>
                                <h2 id="teams-subheading">{i18n.t("team_subheader")}</h2>
                                <h2 id="teams-subheading2">{i18n.t("team_subheader2")}</h2>
                            </header>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-xs-12">
                                <div className="single-team sr-bottom">
                                    <div className="team-img">
                                        <div className="overlay overlay-secondary"></div>
                                        <img src="img/placeholder.jpg" data-src="img/team/luc.jpg" alt="team member 1" width="300" height="330" className="lazy-load" />
                                        <div className="social-links">
                                            <a href="https://www.linkedin.com/in/lucbonnin/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
                                            <a href="https://lucbonnin.sleek.page/" target="_blank" rel="noopener noreferrer"><i className="fa fa-globe"></i></a>
                                            <a href="https://github.com/lucbonnin" target="_blank" rel="noopener noreferrer"><i className="fa fa-github"></i></a>
                                            <a href="https://twitter.com/bonninluc" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
                                        </div>
                                    </div>
                                    <div className="team-title">
                                        <h4>Luc Bonnin</h4>
                                        <p className="text-muted">{i18n.t('ceo')}</p>
                                        <blockquote className="quote">
                                            <p>quote</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="single-team sr-bottom">
                                    <div className="team-img">
                                        <div className="overlay overlay-secondary"></div>
                                        <img src="img/placeholder.jpg" data-src="img/team/james.jpg" alt="team member 3" width="300" height="330" className="lazy-load" />
                                        <div className="social-links">
                                            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/jameshitchcock/" target="_blank"><i className="fa fa-linkedin"></i></a>
                                            <a rel="noopener noreferrer" href="https://jhitchcock.com" target="_blank"><i className="fa fa-globe"></i></a>
                                            <a rel="noopener noreferrer" href="https://github.com/justjamesdev" target="_blank"><i className="fa fa-github"></i></a>
                                        </div>
                                    </div>
                                    <div className="team-title">
                                        <h4>James Hitchcock</h4>
                                        <p className="text-muted">{i18n.t('cto')}</p>
                                        <blockquote className="quote">
                                            <p>quote</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="single-team sr-bottom">
                                    <div className="team-img">
                                        <div className="overlay overlay-secondary"></div>
                                        <img src="img/placeholder.jpg" data-src="img/team/sandrinerade.jpg" alt="team member 3" width="300" height="330" className="lazy-load" />
                                        <div className="social-links">
                                            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/sandrine-rad%C3%A9/" target="_blank"><i className="fa fa-linkedin"></i></a>                                            
                                        </div>
                                    </div>
                                    <div className="team-title">
                                        <h4>Sandrine Radé</h4>
                                        <p className="text-muted">{i18n.t('coo')}</p>
                                        <blockquote className="quote">
                                            <p>quote</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-6">
                                <div className="single-team sr-bottom">
                                    <div className="team-img">
                                        <div className="overlay overlay-secondary"></div>
                                        <img src="img/placeholder.jpg" data-src="img/team/annebeall.jpg" alt="team member 3" width="300" height="330" className="lazy-load" />
                                        <div className="social-links">
                                            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/annebeall/" target="_blank"><i className="fa fa-linkedin"></i></a>
                                            <a rel="noopener noreferrer" href="https://beallrt.com/" target="_blank"><i className="fa fa-globe"></i></a>
                                            <a rel="noopener noreferrer" href="https://twitter.com/beallresearch" target="_blank"><i className="fa fa-twitter"></i></a>
                                        </div>
                                    </div>
                                    <div className="team-title">
                                        <h4>Dr. Anne Beall</h4>
                                        <p className="text-muted">{i18n.t('advisorsAnneBeall')}</p>
                                        <blockquote className="quote">
                                            <p>quote</p>
                                        </blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </section>

                <div className="page-section container-wide dark-bg parallax" data-parallax="scroll" data-image-src="img/personsitting.jpg">

                    <div className="overlay overlay-secondary"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 cta-text text-center mb-10 sr-bottom">
                                <h2>{i18n.t('third_cta_header')}</h2>
                            </div>
                            <div className="col-md-12 col-sm-12 cta-btn text-center width-100 sr-bottom">
                                <a href="#" className="homeTryDemoThirdCta btn btn-primary btn-embossed btn-xl">{i18n.t("third_cta_click")} <i className="fa fa-play-circle"></i></a>
                            </div>
                        </div>
                    </div>

                </div>

                <section id="covid19" className="page-section bg-light">

                    <div className="container">
                        <VizSensor onChange={this.odometerVizSensorChanged}>
                            <div className="row text-center">
                                <header className="col-md-10 col-md-offset-1 col-sm-12 section-heading heading-circle primary-bg sr-top">
                                        <span className="sub-heading">{i18n.t("covid19_subheader")}</span>
                                        <h2 id="covid19-subheading">{i18n.t("covid19_header")}</h2>
                                        <hr className="section-hr" />
                                </header>
                            </div>
                        </VizSensor>
                        {(!(typeof (this.state.emotionsStatistics.countFaceDatapointsAnalyzed) == 'undefined')) && <div
                            className="row mb-30" id="odometerRow">

                                <p className="col-sm-6 covid19RighColTitle">{i18n.t("covid19_boldline_rightcol")}</p>
                                <div className="col-sm-6 text-right">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Odometer
                                                    id="odometerSumPositiveEmotionsInSec"
                                                    value={this.state.odometerValue_SumPositiveEmotionsInSec}
                                                    format={i18n.t('covid19_odometerFormat')}
                                                    duration={5000}
                                                    theme="minimal"

                                                />
                                            </div>
                                            <div className="col-sm-6 odometerSecondsOfHappiness">{i18n.t('covid19_secondsOfHappiness')}</div>
                                        </div>
                                        <div className="row covid19SecondLineTextDataPointAnalyzed">
                                            <span>{i18n.t("covid19_analyzing")}&nbsp;</span>
                                            <Odometer
                                                    id="odometerDataPointAnalyzed"
                                                    value={this.state.odometerValue_CountFaceDatapointsAnalyzed}
                                                    format={i18n.t('covid19_odometerFormat')}
                                                    duration={5000}
                                                    theme="minimal"

                                                />
                                            <span>{i18n.t("covid19_emotionalDataPoints")}</span>
                                            { (this.state.emotionsStatistics.filteredVideosDisplayName!="") && <span>
                                                    &nbsp;({i18n.t("covid19_owingToVideosFrom")} {Parser(this.state.emotionsStatistics.filteredVideosDisplayName)})
                                                </span>
                                            }
                                        </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-12 col-sm-12 section-content">
                                <div className="row mb-20">
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href={this.state.proto3_1stImg} className="homeImgClick_MovieTheaterFaces vb-lightbox"><img src={this.state.proto3_1stImg} data-src={this.state.proto3_1stImg} width="450" height="298" alt="intro 1" className="lazy-load rounded small" /></a>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto3-mask.jpg" className="homeImgClick_Device vb-lightbox"><img src="img/proto3-mask.jpg" data-src="img/proto3-mask.jpg" width="450" height="298" alt="intro 2" className="lazy-load rounded small" /></a>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-6 mb-20 sr-bottom">
                                        <a href="img/proto3-art.jpg" className="homeImgClick_FacePoints vb-lightbox"><img src="img/proto3-art.jpg" data-src="img/proto3-art.jpg" width="450" height="298" alt="intro 3" className="lazy-load rounded small" /></a>
                                    </div>
                                </div>
                        </div>

                    </div>
                </section>





                <footer className="site-footer" id="footer">
                    <div className="container inner">
                        <div className="row multi-columns-row">
                            <div className="col-md-4 col-lg-3 mb-30">
                                <h4>{i18n.t('footer_left_header')}</h4>
                                <div className="footer-logo">
                                    <img src="img/placeholder.jpg" className="logo lazy-load" data-src="img/logo-light.png" alt="Logo" />
                                </div>
                                <p>{i18n.t('footer_left_content')}</p>
                            </div>
                            <div className="col-md-5 col-lg-6 mb-30">
                                <h4><strong>{i18n.t('footer_mid_header')}</strong></h4>
                                <p>{i18n.t('footer_mid_content')}</p>
                                <ul className="contact-list">
                                    <li><i className="fa fa-fw fa-envelope-o"></i><a href="mailto:contact@haalia.ai">contact@haalia.ai</a></li>
                                    <li><i className="fa fa-fw far fa-edit"></i><a href="javascript: window.openContactForm();">{i18n.t('contact_form')}</a></li>
                                </ul>


                            </div>
                            <div className="col-md-3 col-lg-3 mb-30">
                                <h4>{i18n.t('footer_right_header')}</h4>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="block-list-item social-media">
                                            <li><a href="https://www.facebook.com/Haalia-111007517373388" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook footerLinkFacebook"></i><span>Facebook</span></a></li>
                                            <li><a href="https://www.youtube.com/channel/UCDWNhup53mTaIo-Ak5WGXXw" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube footerLinkYoutube"></i><span>Youtube</span></a></li>
                                            <li><a href="https://twitter.com/haalia_ai" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter footerLinkTwitter"></i><span>Twitter</span></a></li>
                                            <li><a href="https://www.linkedin.com/company/haalia/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin footerLinkLinkedIn"></i><span>LinkedIn</span></a></li>
                                            <li><a href="https://t.me/haalia_ai" target="_blank" rel="noopener noreferrer"><i className="fa fa-telegram footerLinkLinkedIn"></i><span>Telegram Group</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className="pull-left">{i18n.t('footer_copyright')}</p>
                                    <ul className="pull-right inline-block-list-item">
                                        <li><a href="/haalia-privacy-policy.pdf">{i18n.t('footer_privacy')}</a></li>

                                    </ul>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <a href="#" id="back-to-top" title={i18n.t('backtoTop')}><i className="fa fa-chevron-up"></i></a>

                <ContactWidget />

            </div>

        )

    }

}

export default withRouter(MainPage);