import React from 'react';
import i18n from "../i18n";

class MainNavigation extends React.Component {
	storedEmail = '';
	componentDidMount() {
        this.storedEmail = localStorage.getItem('email');
        if(this.storedEmail !== null) {
            console.log('MainNavigation : email retrieved from localstorage ['+this.storedEmail+']');
		}
		else {
			this.storedEmail = '';
		}
	}

	goHome = (e) => {
        // Terminado
		e.preventDefault()
		if(this.storedEmail !== '') { // hack to clear localStorage
			console.log('MainNavigation : clear localstorage now !');
			localStorage.clear();
		}
        document.location.href = "/"; // hack to WA none-react theme
	}

    render() {

        return (

            <nav className="navbar navbar-default navbar-fixed-top main-nav">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="navbar-header page-scroll">
							<button type="button" className="navbar-toggle" data-toggle="collapse"
								data-target=".navbar-ex1-collapse">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<a href="/" onClick={this.goHome} className="navbar-left">
								<img src="img/logo-light.png" className="logo logo-light" alt="Haalia Logo" width="170"
									height="50" />
								<img src="img/logo-dark_orange-black.png" className="logo logo-dark" alt="Haalia Logo" width="170"
									height="50" />
							</a>
						</div>
						<div className="collapse navbar-collapse navbar-ex1-collapse">
							<ul className="nav navbar-nav navbar-right main-menu">
								<li className="active hidden">{
									// eslint-disable-next-line jsx-a11y/anchor-has-content
								}<a className="page-scroll" href="#page-top"></a>
								</li>
								<li>
									<a className="page-scroll topBarAbout" href="#intro">{i18n.t('nav_about')}</a>
								</li>
								<li>
									<a className="page-scroll topBarServices" href="#services">{i18n.t('nav_services')}</a>
								</li>

								<li>
									<a className="page-scroll topBarTeam" href="#team">{i18n.t('nav_team')}</a>
								</li>
								<li>
									<a className="page-scroll topBarCovid19" href="#covid19">{i18n.t('nav_covid19')}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</nav>



        )

    }

}

export default MainNavigation;














