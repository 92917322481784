import i18n from "i18next";
//import Locize from "i18next-locize-backend";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../src/locales/en/translations.en.json';
import translationES from '../src/locales/es/translations.es.json';
import translationFR from '../src/locales/fr/translations.fr.json';

const resources = {
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    }
};

const detectionOptions = {
    order: [ 'queryString', 'cookie', 'navigator', 'localStorage', 'subdomain', 'htmlTag', 'path'],
    //order: [ 'queryString'],
    lookupQuerystring: 'lang',
    lookupFromPathIndex: 0
}

i18n
    .use(detector)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
       // lng: "en",
        fallbackLng: "en", // use en if detected lng is not available
        debug: false,
        detection: detectionOptions,
        saveMissing: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss so all good
        }
    }, (err, t) => {
        if (err)
            console.error(err)
    });
export default i18n;
