import config from '../config.json';
import Cookies from 'js-cookie';

export const crmService = {
    saveCaptureEmailForm,
    trackPageView,
    trackEvent
};

async function trackPageView(path) {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
}

async function trackEvent(id, value) {
    var _hsq = window._hsq = window._hsq || [];
    _hsq.push(["trackEvent", { id: id, value: value }]);
}

async function saveCaptureEmailForm(email) {
    const formOptions = {
        "submittedAt": new Date().getTime(),
        "fields": [
            {
                "name": "email",
                "value": email
            },
            // {
            //     "name": "leadSource",
            //     "value": "emailCaptureForm"
            // }
        ],
        "context": {
            "hutk": Cookies.get('hubspotutk'),
            "pageUri": "haalia.ai",
            "pageName": "Root"
        }
        // "legalConsentOptions": {
        //   "consent": { // Include this object when GDPR options are enabled
        //     "consentToProcess": true,
        //     "text": "By checking this box I agree to the terms and conditions described in the Privacy Policy",
        //     "communications": [
        //       {
        //         "value": true,
        //         "subscriptionTypeId": 999,
        //         "text": "I agree to receive marketing communications from Example Company."
        //       }
        //     ]
        //   }
        // }
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formOptions)
    };

    fetch(`${config.HUBSPOT_FORM_URL}/7718771/f808c448-11ff-4879-8ecc-b17c2870a195`, requestOptions).then((response) =>
        response.json().then(json => console.log(json))
    );
}

