import React from 'react';
import i18n from '../i18n';

import { GradientDefs, DiscreteColorLegend } from 'react-vis';
import i18next from 'i18next';


var ITEMS;

class TreeMapLegend extends React.Component {

  
  constructor() {
    super();    
    ITEMS = [
      {title: i18n.t('emotion_happy'), color: "green", },
      {title: i18n.t('emotion_surprised')+' - ', color: 'orange'},
      {title: i18n.t('emotion_sad'), color: 'red'},
      {title: i18n.t('emotion_angry'), color: 'darkred'},
      {title: i18n.t('emotion_disgusted'), color: 'purple'},
      {title: i18n.t('emotion_fearful'), color: 'hotpink'},
      {title: i18n.t('emotion_neutral'), color: '#4545a0'},
    ];
    

   }

   componentDidMount() {
    console.log('test2 : ',this.props);
    ITEMS = [
      {title: i18n.t('emotion_happy')+(typeof(this.props.happy)!='undefined'?' - '+this.props.happy+'%':''), color: "green", },      
      {title: i18n.t('emotion_surprised')+(typeof(this.props.surprised)!='undefined'?' - '+this.props.surprised+'%':''), color: 'orange'},
      {title: i18n.t('emotion_sad')+(typeof(this.props.sad)!='undefined'?' - '+this.props.sad+'%':''), color: 'red'},
      {title: i18n.t('emotion_angry')+(typeof(this.props.angry)!='undefined'?' - '+this.props.angry+'%':''), color: 'darkred'},
      {title: i18n.t('emotion_disgusted')+(typeof(this.props.disgusted)!='undefined'?' - '+this.props.disgusted+'%':''), color: 'purple'},
      {title: i18n.t('emotion_fearful')+(typeof(this.props.fearful)!='undefined'?' - '+this.props.fearful+'%':''), color: 'hotpink'},
      {title: i18n.t('emotion_neutral')+(typeof(this.props.neutral)!='undefined'?' - '+this.props.neutral+'%':''), color: '#4545a0'},
    ];
   }

  render() {
    return (
      <div className="treemapLegendContainer">
        <DiscreteColorLegend orientation="horizontal"  items={ITEMS} />
      </div>
    );
  }
}
export default TreeMapLegend;
