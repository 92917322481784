import React from 'react';
import i18n from "../i18n";

class LoadingOverlay extends React.Component {

    render() {
        return (

            <div className="loading-overlay">
                <div className="row">
                    <div className="loading-content" style={{minWidth: '300px', maxWidth: '500px', width: '60%'}}>
                        <img src="/img/logo-dark_orange-black.png" style={{ display: 'inline', maxWidth: '80%', margin:'auto' }} alt="Haalia Logo" />
                            <h2 style={{margin: 'auto'}}>{i18n.t('hero_type_2')}</h2>
                    </div>
                </div>
            </div>

        )
    }
}

export default LoadingOverlay;

